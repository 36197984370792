<template>
  <div class="mod-config">
    <div class="wxts_msg_search" >
      <div class="f_t">
        <el-button-group  >
        <el-button type="success" @click="getDataList()" :disabled="dataListLoading">{{ $t('refresh') }}</el-button>

       <!--  <el-button v-if="isAuth('msg:template')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0 || dataListLoading">批量删除</el-button> -->
        </el-button-group>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%;">
      <table-tree-column
        prop="name"
        header-align="center"
        treeKey="id"
        width="300"
        show-overflow-tooltip
        :label="$t('msg.type.name')">
      </table-tree-column>
      <el-table-column
        prop="code"
        align="center"
        :label="$t('msg.type.ID')">
      </el-table-column>
      <el-table-column
        prop="parentName"
        align="center"
        :label="$t('msg.type.superiorName')">
      </el-table-column>
      <el-table-column
        prop="msgTitle"
        align="center"
        show-overflow-tooltip
        :label="$t('msg.type.modelTitle')">
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="messagePoint"-->
<!--        align="center"-->
<!--        show-overflow-tooltip-->
<!--        :label="$t('msg.type.messagePoint')">-->
<!--        <template slot-scope="scope">-->
<!--           <sysDict  type="message_point" :value="scope.row.messagePoint"/>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
        fixed="right"
        align="center"
        width="150"
        :label="$t('msg.type.operate')">
        <template slot-scope="scope" >
          <div v-if="(scope.row.code === '1' || scope.row.code === '2' || scope.row.code === '3')">
            <el-button type="text" size="small" v-if="isAuth('msg:type')" @click="addOrUpdateHandle(scope.row.messageType, scope.row.id)">{{ $t('add') }}</el-button>
          </div>
          <div v-else>
            <el-button type="text" size="small" v-if="isAuth('msg:type')" @click="addOrUpdateHandle(scope.row.messageType,scope.row.parentId,scope.row.id)">{{ $t('update') }}</el-button>
            <el-button type="text" size="small" v-if="isAuth('msg:type')" @click="deleteHandle(scope.row)">{{ $t('delete') }}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import TableTreeColumn from '@/components/table-tree-column'
  import AddOrUpdate from './add-or-update'
  import { getList , deleteData } from '@/api/msg/type.js'
  import { treeDataTranslate } from "@/utils";

  export default {
    data () {
      return {
        dataForm: {
          key: ''
        },
        dataList: [],

        dataListLoading: false,
        addOrUpdateVisible: false
      }
    },
    components: {
      TableTreeColumn,AddOrUpdate
    },
    activated () {
      this.getDataList();
    },
    methods: {
      refresh(){
        // this.$router.push({name:'msg_sysmsgtype',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true

        getList().then(({data}) => {
          if (data && data.code === 0) {
            //this.dataList = data.data
            const list = data.data;
            this.dataList = treeDataTranslate(list, "id");

          } else {
            this.dataList = []
            this.$message.error(data.msg)
          }
        }).catch((err) => {
            console.error(err)
        }).finally(()=>{
        	this.dataListLoading = false
        })
      },
      // 新增 / 修改
      addOrUpdateHandle (messageType, parentId, id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(messageType, parentId, id)
        })
      },
      // 删除
      deleteHandle (row) {
        const ids = row ? [row.id] : this.dataListSelections.map(item => {
          return item.id
        })
        //const names = row ? [row.name] : this.dataListSelections.map(item => {
        //  return item.name
        //})

        this.$confirm(this.$t('msg.type.tip4')+`${row ? this.$t('delete') : this.$t('msg.type.deleteBash')}`+this.$t('operate'), this.$t('msg.type.title'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          this.dataListLoading = true
          deleteData(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('success'),
                type: 'success',
                duration: 1000
              })
              this.getDataList()
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            console.error(err)
          }).finally(()=>{
          	this.dataListLoading = false
          })
        }).catch(_ => {})
      }
    }
  }
</script>
